<template>
  <main>
<div id="main">
    <img style="margin:0 auto" src="https://macartebrico.herokuapp.com/_nuxt/img/0ac9cfc.svg" alt="Logo Kome">
     <br><br>
    <h2>Notre site est actuellement en construction !<br>
     

    </h2>
    <br><br>
    <div id="progress">
        <div id="fill">
            <div id="barpercent">
                <h3>75%</h3>
            </div>
        </div>
    </div>
</div>
  </main>
</template>
<script>
export default {
  name: 'home' 
}
</script>
<style>
@import url('https://fonts.googleapis.com/css?family=Roboto:400,300');
body {margin: 0;padding: 0;}     /*CSS Reset*/
html {height: 100%;}
body {
    font-family: 'Roboto', sans-serif;     /*Default Font*/
    width: 100%;
    height: 100%;
    text-align: center;     /*Align Text to Center*/
    display: table;
    -webkit-animation: fadeinelement 3s;
    animation: fadeinelement 3s;
}

main {
    display: flex;
  align-items: center;

}
h1,h2,h3 {
    cursor: default;     /*When hover, default cursor displays*/
}
h2 {font-weight: 300;margin-bottom: 25px;}
img {
    width: 25%;     /*Define Image Width*/
    width: 25vw;
    border: 0;     /*For IE*/
}
#main {vertical-align: middle;display: table-cell; margin:0 auto; text-align: center;}

#main.img {
  margin: 0 auto;;
}
#progress {
    width: 30%;     /*Changes Percentage of Bar Total Width*/
    width: 30vw;
    border-radius: 22px;     /*Roundness of Bar*/
    background-color: #a8a8a8;     /*Background Colour of Bar*/
    margin: 0 auto;     /*Center Object*/
    padding: 7px 5px;
    /*border: 5px solid #a8a8a8; - Border Colour of Bar*/
}
#fill {
    padding: 2px 0;
    background-color: #f9b212;     /*Fill of Bar*/
    width: 75%;     /*Bar Percent, Can be Customized down Below*/
    border-radius: 25px;     /*Bar Roundness*/
    -webkit-animation: proanimate 2s;
    animation: proanimate 2s;
}
#barpercent h3 {
    color: white;     /*Bar Percentage Text Colour*/
    margin: 0;
    padding: 0;
    opacity: 0;
    -webkit-animation: fadeinelement 3s forwards;
    animation: fadeinelement 3s forwards;
}
/*Fades in Page*/
@keyframes fadeinelement {from {opacity: 0;} to {opacity: 1;}}
@-webkit-keyframes fadeinelement {from {opacity: 0;}to {opacity: 1;}}
/* Animates Bar Moving on Page Load*/
@keyframes proanimate {from {width: 0%;}}
@-webkit-keyframes proanimate {from {width: 0%;}}
/*Responsive*/
@media screen and (max-width:600px) {
    img {width: 80%;width: 60vw;}
    h1 {font-size: 1.3em;}
    h2 {margin: 1.1em;font-size: 1.1em;}
    #progress {width: 80%;}     /*Changes Percentage of Bar Width*/
}
</style>